import IconButton from 'components/common/IconButton';
import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import { FormatDate } from 'helpers/FormatData';
import { httpClient } from 'helpers/HttpClient';
import MyModal from 'helpers/MyModal';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CheckPlanning from './_CheckPlanning';
import ChangesPLanning from './_ChangesPLanning';
import ChangeTask from './_ChangeTask';

const DetailPlanning = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  let { id } = useParams();
  const [planning, setPlanning] = useState(null);
  const [openPrint, setOpenPrint] = useState(null);
  const [uidRefresh, setUidRefresh] = useState(null);
  const [taskIdSelected, setTaskIdSelected] = useState(null);
  const [statusIdSelected, setStatusIdSelected] = useState(null);
  const [openEditTask, setOpenEditTask] = useState(null);

  const handleClose = () => {
    setOpenPrint(false);
    setOpenEditTask(false);

    setUidRefresh(new Date().getTime());
    getPlanning();
  };

  const getPlanning = () => {
    httpClient(
      'POST',
      ApiEndpoint + 'planning/get',
      { userid: userData.uid_user, id: id },
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          setPlanning(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getPlanning();
  }, []); // empty array makes hook working once

  return (
    <>
      {planning && (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row className="justify-content-between align-items-center">
                <Col md>
                  <h5 className="mb-2 mb-md-0">Solicitud {planning.vc_id}</h5>
                </Col>
                <Col xs="auto">
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-down"
                    className="me-1 mb-2 mb-sm-0"
                    iconClassName="me-1"
                  >
                    Download (.pdf)
                  </IconButton>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="print"
                    iconClassName="me-1"
                    className="me-1 mb-2 mb-sm-0"
                    onClick={() => {
                      setOpenPrint(true);
                    }}
                  >
                    Imprimir
                  </IconButton>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row>
            <Col xs={9}>
              <Card className="mb-3">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col>
                      <h6 className="text-500">Cliente</h6>
                      <h5>{planning.vc_name}</h5>
                      <p className="fs--1">{planning.vc_institucion}</p>
                      <p className="fs--1">
                        Area {planning.vc_area}
                        <br />
                        Tipo Contratacion {planning.vc_tipocontratacion}
                        <br />
                        Partida {planning.vc_partidaspresupuestales}
                      </p>
                    </Col>
                    <Col sm="auto" className="ms-auto">
                      <div className="table-responsive">
                        <Table borderless size="sm" className="fs--1">
                          <tbody>
                            <tr>
                              <th className="text-sm-end">Solicitud No:</th>
                              <td>{planning.vc_id}</td>
                            </tr>
                            <tr>
                              <th className="text-sm-end">Recepcion:</th>
                              <td>{FormatDate(planning.dt_start)}</td>
                            </tr>
                            {planning.dt_end && (
                              <tr>
                                <th className="text-sm-end">
                                  Fecha de Entrega:
                                </th>
                                <td>
                                  {FormatDate(
                                    planning.dt_start ?? planning.dt_end
                                  )}
                                </td>
                              </tr>
                            )}
                            <tr className="alert-success fw-bold">
                              <th className="text-sm-end">Estimado:</th>
                              <td>{planning.n_amount}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>{' '}
                  <Row>
                    <CheckPlanning
                      uidRefresh={uidRefresh}
                      setStatusIdSelected={setStatusIdSelected}
                      setTaskIdSelected={setTaskIdSelected}
                      openEditTask={setOpenEditTask}
                    ></CheckPlanning>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mb-3">
                <Card.Body>
                  <Row className="align-items-center text-center mb-3">
                    <Col className="text-sm  mt-3 mt-sm-0">
                      <p className="text-body-emphasis fw-bold">
                        Status Dictamen:
                      </p>
                      <h5> {planning.vc_statusdictamen}</h5>
                    </Col>
                    <Col className="text-sm  mt-3 mt-sm-0">
                      <p className="text-body-emphasis fw-bold">Status FT:</p>
                      <h5> {planning.vc_statusficha ?? '-'}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <hr />
                    </Col>
                  </Row>
                  <div className="mt-4 fw-bold">Historial de Cambios:</div>
                  <hr />
                  <ChangesPLanning id={id}></ChangesPLanning>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}

      {openPrint && (
        <MyModal
          id="id_myModal_print"
          title={'Orden de Reparacion'}
          openModal={openPrint}
          closeModal={handleClose}
        >
          {/* <ReparacionPreview idReparacion={id} closeModal={handleClose} /> */}
        </MyModal>
      )}

      {openEditTask && (
        <MyModal
          id="id_myModal_print"
          title={'Modificar status de Tarea'}
          openModal={openEditTask}
          closeModal={handleClose}
        >
          <ChangeTask
            taskId={taskIdSelected}
            closeModal={handleClose}
            statusIdSelected={statusIdSelected}
          />
          {/* <ReparacionPreview idReparacion={id} closeModal={handleClose} /> */}
        </MyModal>
      )}
    </>
  );
};

export default DetailPlanning;
