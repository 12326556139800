import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import MyModal from 'helpers/MyModal';
import GenericTableHeader from 'components/GenericTableHeader';
import EditPartidaPresupuestal from './_EditPartidaPresupuestal';
import { useAuth } from 'context/AuthProvider';
import { httpClient } from 'helpers/HttpClient';
const URI = ApiEndpoint + 'configuration/partidaspresupuestales';

const PartidasPresupuestales = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [partidas, setPartidas] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [idPartida, setIdPartida] = useState(0);

  const handleClose = () => {
    setOpenModal(false);
    getPartidasList();
  };

  useEffect(() => {
    getPartidasList();
  }, []);

  const getPartidasList = async () => {
    setPartidas(null);

    httpClient('GET', URI, {}, userData.token)
      .then(function (response) {
        if (!response.data.error) {
          setPartidas(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      accessor: 'IdPartidasPresupuestales',
      Header: '#',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'PartidasPresupuestales',
      Header: 'Partida Presupuestal',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let IdPartidasPresupuestales =
          rowData.row.original.IdPartidasPresupuestales;
        return (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip>Editar</Tooltip>}>
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdPartida(IdPartidasPresupuestales);
                  setOpenModal(true);
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  return (
    <>
      {partidas && (
        <AdvanceTableWrapper
          columns={columns}
          data={partidas}
          sortable
          pagination
          perPage={10}
        >
          <Card className="mb-3">
            <Card.Header>
              <GenericTableHeader
                label="Partidas Presupuestales"
                newFunction={() => {
                  setIdPartida(0);
                  setOpenModal(true);
                }}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}
      {openModal && (
        <MyModal
          id="id_myModal"
          title={
            (idPartida > 0 ? 'Editar' : 'Agregar') + ' Partida Presupuestal'
          }
          openModal={openModal}
          closeModal={handleClose}
        >
          <EditPartidaPresupuestal
            idPartida={idPartida}
            closeModal={handleClose}
          />
        </MyModal>
      )}
    </>
  );
};

export default PartidasPresupuestales;
