import { Button, CloseButton, Modal } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const MyModal = ({
  title,
  openModal,
  closeModal,
  children,
  isConfirm,
  onConfirm,
  isFullScreen = false
}) => {
  const handleClose = () => closeModal(false);

  return (
    <>
      <Modal
        show={openModal}
        onHide={() => closeModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={isFullScreen}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"> {title}</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => closeModal(false)}
          />
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {!isConfirm && <Button onClick={() => handleClose()}>Close</Button>}
          {isConfirm && (
            <>
              {' '}
              <Button
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  onConfirm();
                  handleClose();
                }}
              >
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

MyModal.propTypes = {
  title: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isConfirm: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool
};
export default MyModal;
