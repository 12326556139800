import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import { FormatDate } from 'helpers/FormatData';
import { httpClient } from 'helpers/HttpClient';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ChangesPLanning = () => {
  const Auth = useAuth();
  let { id } = useParams();
  var userData = Auth.getSessionData();
  const [changes, setChanges] = useState(null);
  const getChanges = () => {
    httpClient(
      'POST',
      ApiEndpoint + 'plannings/getchanges',
      { userid: userData.uid_user, id: id },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          setChanges(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getChanges();
  }, []);
  return (
    <>
      <Table striped className="border-bottom" style={{ minHeight: '500px' }}>
        <tbody>
          {changes ? (
            changes.map(detail => (
              <tr style={{ height: '80px' }} key={detail.tst_change}>
                <td className="align-middle">
                  <Card.Text>
                    <Row>
                      <Col className="sm">{detail.vc_comments}</Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mb-0 text-body-emphasis sm">
                          {FormatDate(detail.tst_change)}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mb-0 fs-11 fw-bolder sm">
                          {detail.vc_statusdictamen}
                        </p>
                      </Col>
                    </Row>
                  </Card.Text>
                </td>
              </tr>
            ))
          ) : (
            <>
              <div className="alert alert-light sm" role="alert">
                Sin cambios registrados
              </div>
            </>
          )}
          <tr>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ChangesPLanning;
