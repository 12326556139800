import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
const URI = ApiEndpoint;
import PropTypes from 'prop-types';
import { FormInputText } from 'components/formcomponents/FormInputText';
import { useAuth } from 'context/AuthProvider';
import { httpClient } from 'helpers/HttpClient';
import uuid from 'react-uuid';

const EditUser = ({ idUser, closeModal }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [confirmPassword, setConfirmPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState({
    password: '',
    confirmPassword: ''
  });
  const [user, setUser] = useState({
    IdUser: '',
    Nombre: '',
    User: '',
    ApellidoPaterno: '',
    ApellidoMaterno: '',
    FechaAlta: '0',
    Status: '',
    email: ''
  });
  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      IdUser: '',
      Nombre: '',
      User: '',
      ApellidoPaterno: '',
      ApellidoMaterno: '',
      FechaAlta: '0',
      Status: ''
    }
  });
  useEffect(() => {
    if (idUser === 0) return;
    httpClient('GET', URI + 'users/' + idUser, {}, userData.token)
      .then(function (response) {
        setUser(response.data);
        setIsActive(response.data.Status);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (
      form.checkValidity() === false ||
      (error.password !== error.confirmPassword && (!idUser || idUser === 0))
    ) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    if (idUser > 0) {
      httpClient(
        'PUT',
        URI + 'users/' + idUser,
        {
          user: user.User,
          name: user.Nombre,
          firstname: user.ApellidoPaterno,
          lastname: user.ApellidoMaterno,
          email: user.FechaAlta
        },
        userData.token
      )
        .then(function (response) {
          if (!response.data.error) {
            closeModal();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      httpClient(
        'POST',
        URI + 'users/',
        {
          user: user.User,
          name: user.Nombre,
          firstname: user.ApellidoPaterno,
          lastname: user.ApellidoMaterno,
          password: password,
          userid: uuid(),
          email: user.email
        },
        userData.token
      )
        .then(function (response) {
          if (!response.data.error) {
            closeModal();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const onChange = event => {
    console.log(event.target);
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const onChangeActive = () => {
    setIsActive(!isActive);
  };
  const onChangePassword = event => {
    setPassword(event.target.value);
    validateInput(event);
  };
  const onChangeConfirm = event => {
    setConfirmPassword(event.target.value);
    validateInput(event);
  };

  const validateInput = e => {
    if (idUser > 0) {
      return;
    }
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: '' };

      switch (name) {
        case 'password':
          if (!value) {
            stateObj[name] = 'Ingrese la contraseña';
          } else if (confirmPassword && value !== confirmPassword) {
            stateObj['confirmPassword'] = 'Las contraseñas no coinciden.';
          } else if (value.length < 6) {
            stateObj['password'] = 'Contraseña muy pequeña';
          } else {
            stateObj['confirmPassword'] = confirmPassword
              ? ''
              : error.confirmPassword;
          }
          break;

        case 'confirmPassword':
          if (!value) {
            stateObj[name] = 'Por favor confirme la contraseña.';
          } else if (password && value !== password) {
            stateObj[name] = 'La contraseñas no coinciden.';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label="Nombre"
                  changeHandler={onChange}
                  name={'Nombre'}
                  control={control}
                  defaultValue={user?.Nombre}
                ></FormInputText>
              </Col>
              <Col>
                <FormInputText
                  control={control}
                  label="ApellidoPaterno"
                  name="ApellidoPaterno"
                  changeHandler={onChange}
                  defaultValue={user?.ApellidoPaterno}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  controlId="Apellido Materno"
                >
                  <FormInputText
                    control={control}
                    label="ApellidoMaterno"
                    name="ApellidoMaterno"
                    changeHandler={onChange}
                    defaultValue={user?.ApellidoMaterno}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="city">
                  <FormInputText
                    control={control}
                    label="Email"
                    name="email"
                    changeHandler={onChange}
                    defaultValue={user?.email}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="Usuario">
                  <FormInputText
                    control={control}
                    label="Usuario"
                    name="User"
                    changeHandler={onChange}
                    defaultValue={user?.User}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="isActive">
                  <Form.Check
                    type="switch"
                    id="Status"
                    label="Activo?"
                    checked={isActive}
                    onChange={onChangeActive}
                  />
                </Form.Group>
              </Col>
            </Row>
            {idUser === 0 && (
              <Row>
                <Col>
                  <FormInputText
                    label="Contraseña"
                    changeHandler={onChangePassword}
                    name={'password'}
                    control={control}
                    type="password"
                    required={false}
                    defaultValue={password}
                  ></FormInputText>
                  {error.password && (
                    <span className="err">{error.password}</span>
                  )}
                </Col>
                <Col>
                  <FormInputText
                    control={control}
                    label="Confirme Contraseña"
                    name="confirmPassword"
                    type="password"
                    required={false}
                    changeHandler={onChangeConfirm}
                    defaultValue={confirmPassword}
                  ></FormInputText>
                  {error.confirmPassword && (
                    <span className="err">{error.confirmPassword}</span>
                  )}
                </Col>
              </Row>
            )}
            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

EditUser.propTypes = {
  idUser: PropTypes.number.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isConfirm: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool
};
export default EditUser;
