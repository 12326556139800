import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import { useEffect } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import MyModal from 'helpers/MyModal';
import ConfirmAction from 'helpers/ConfirmationModal';
import GenericTableHeader from 'components/GenericTableHeader';
import EditInstitucion from './_EditInstitucion';
import { useAuth } from 'context/AuthProvider';
import { httpClient } from 'helpers/HttpClient';
const URI = ApiEndpoint + 'configuration/';

const Instituciones = () => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [instituciones, setInstituciones] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [idInstitucion, setIdInstitucion] = useState(0);

  const [openConfirm, setOpenConfirm] = useState(null);
  const [idInstitucionToDelete, setIdInstitucionToDelete] = useState(0);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    getInstitucionesList();
  };
  const handleClose = () => {
    setOpenModal(false);
    getInstitucionesList();
  };

  useEffect(() => {
    getInstitucionesList();
  }, []);

  const getInstitucionesList = async () => {
    setInstituciones(null);

    httpClient('GET', URI + 'instituciones', {}, userData.token)
      .then(function (response) {
        if (!response.data.error) {
          setInstituciones(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const DeleteConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    httpClient(
      'DELETE',
      URI + 'instituciones/' + idInstitucionToDelete,
      {},
      userData.token
    )
      .then(function (response) {
        if (response.data.error) {
          return;
        }
        getInstitucionesList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      accessor: 'idInstituciones',
      Header: 'idInstituciones',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'Institucion',
      Header: 'Institucion',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idInstituciones = rowData.row.original.idInstituciones;
        return (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip>Editar</Tooltip>}>
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdInstitucion(idInstituciones);
                  setOpenModal(true);
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Eliminar</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdInstitucionToDelete(idInstituciones);
                  setOpenConfirm(true);
                }}
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];
  return (
    <>
      {instituciones && (
        <AdvanceTableWrapper
          columns={columns}
          data={instituciones}
          sortable
          pagination
          perPage={10}
        >
          <Card className="mb-3">
            <Card.Header>
              <GenericTableHeader
                label="Instituciones"
                newFunction={() => {
                  setIdInstitucion(0);
                  setOpenModal(true);
                }}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}
      {openModal && (
        <MyModal
          id="id_myModal"
          title={(idInstitucion > 0 ? 'Editar' : 'Agregar') + ' Usuario'}
          openModal={openModal}
          closeModal={handleClose}
        >
          <EditInstitucion
            idInstitucion={idInstitucion}
            closeModal={handleClose}
          />
        </MyModal>
      )}

      {openConfirm && (
        <ConfirmAction
          message={'Desea eliminar el registro?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={DeleteConfirmed}
        ></ConfirmAction>
      )}
    </>
  );
};

export default Instituciones;
