import { FormInputText } from 'components/formcomponents/FormInputText';
import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import { httpClient } from 'helpers/HttpClient';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';

import { v4 as uuidv4 } from 'uuid';

const ChangeTask = ({ taskId, closeModal, statusIdSelected }) => {
  let { id } = useParams();
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [statusTaskList, setStatusTaskList] = useState([]);
  const [statusId, setStatusId] = useState(statusIdSelected);
  const [completed, setCompleted] = useState(false);
  const [validated, setValidated] = useState(false);
  const [listFiles, setListFiles] = useState([]);
  //const [totalFiles, setTotalFiles] = useState(0);

  const fileTypes = [
    'JPG',
    'PNG',
    'GIF',
    'PDF',
    'JPEG',
    'ZIP',
    'XLSX',
    'XLS',
    'DOC',
    'DOCX',
    'CSV'
  ];
  const [values, setValues] = useState({
    comments: ''
  });
  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      comments: ''
    }
  });
  const getTaskStatusList = async () => {
    setStatusTaskList(null);

    httpClient(
      'GET',
      ApiEndpoint + 'configuration/statustasks',
      {},
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          setStatusTaskList(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getTaskStatusList();
  }, []);
  const onSubmit = (data, e) => {
    console.log('listfiles', listFiles);
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'planningtask/create';
    setValidated(true);

    httpClient(
      'POST',
      URI,
      {
        completed: completed,
        comments: values.comments,
        statustask: statusId,
        task: taskId,
        id: id,
        userid: userData.uid_user,
        filename:
          listFiles && listFiles.length > 0
            ? 'file_' + listFiles[0].fileId + '.' + listFiles[0].ext
            : ''
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          closeModal();
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleChangeFile = file => {
    console.log(file);
    uploadFile(file);
  };

  const uploadFile = file => {
    console.log(file);
    const newUidFile = uuidv4();
    const formData = new FormData();
    formData.append('name', 'ServiceFile');
    formData.append('path', 'files');
    formData.append('uuid', newUidFile);
    formData.append('ServiceFile', file);
    const URISaveFile = ApiEndpoint + 'planningtask/savefile/';

    httpClient('POST', URISaveFile, formData, userData.token, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        console.log('response', response);
        let extArray = file.name.split('.');
        let extension = extArray[extArray.length - 1];
        const newFile = {
          fileId: newUidFile,
          fileName: file.name,
          originalname: file.name,
          filetype: file.type,
          ext: extension
        };

        addFile(newFile);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addFile = newFile => {
    console.log('newFile', newFile);
    let files = listFiles;
    files.push(newFile);
    setListFiles(files);
    //setTotalFiles(totalFiles + 1);
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <InputGroup size="sm">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    Status
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Default select"
                    size="sm"
                    name="status"
                    onChange={selectedOption => {
                      setStatusId(`${selectedOption.target.value}`);
                    }}
                    value={`${statusId}`}
                  >
                    <option key={'status_0'} value={0}>
                      Seleccione una Opcion
                    </option>
                    {!!statusTaskList?.length &&
                      statusTaskList.map(item => (
                        <option
                          key={item.idStatusTask}
                          value={item.idStatusTask}
                        >
                          {item.StatusTask}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Observaciones"
                  changeHandler={onChange}
                  name={'comments'}
                  control={control}
                  defaultValue={values.comments}
                  type="text"
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  type="switch"
                  id="isRequired"
                  label="Marcar como Completado"
                  checked={completed}
                  onChange={() => {
                    setCompleted(!completed);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FileUploader
                  handleChange={handleChangeFile}
                  name="dictamenFile"
                  id={'dictamenFile'}
                  types={fileTypes}
                />
              </Col>
            </Row>
            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
ChangeTask.propTypes = {
  closeModal: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
  statusIdSelected: PropTypes.number
};
export default ChangeTask;
