import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import AppContext from '../context/Context';
import MainLayout from './MainLayout';
import Dashboard from 'dashboards/DashboardIndex';
import Users from 'pages/security/Users';
import Instituciones from 'pages/configuration/Instituciones';
import PartidasPresupuestales from 'pages/configuration/PartidasPresupuestales';
import AuthSimpleLayout from './AuthSimpleLayout';
import Logout from 'components/authentication/card/Logout';
import Login from 'pages/account/Login';
import DetailPlanning from 'pages/plannings/DetailPlanning';
import DashboardSheets from 'dashboards/DashboardSheets';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/thechsh" element={<DashboardSheets />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/Instituciones" element={<Instituciones />} />
          <Route
            path="/PartidasPresupuestales"
            element={<PartidasPresupuestales />}
          />
          <Route path="/Planning/:id" element={<DetailPlanning />} />
        </Route>
        <Route element={<AuthSimpleLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/security/Logout" element={<Logout />} />
        </Route>
      </Routes>
    </>
  );
};

export default Layout;
