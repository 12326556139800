import { FormInputText } from 'components/formcomponents/FormInputText';
import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import { httpClient } from 'helpers/HttpClient';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

const ConfirmPlaning = ({ closeModal, uidPlaning }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);
  const [statusList, setStatusList] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [planning, setPlanning] = useState(null);
  const [values, setValues] = useState({
    Observaciones: ''
  });
  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      Observaciones: ''
    }
  });
  const getStatusList = async () => {
    httpClient(
      'GET',
      ApiEndpoint + 'configuration/statusdictamen',
      {},
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          setStatusList(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPlanning = async () => {
    httpClient(
      'POST',
      ApiEndpoint + 'planning/get',
      { userid: userData.uid_user, id: uidPlaning },
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          setPlanning(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getPlanning();
    getStatusList();
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'planning/edit';
    setValidated(true);

    httpClient(
      'POST',
      URI,
      {
        id: uidPlaning,
        status: statusId,
        comments: values.Observaciones,
        userid: userData.uid_user
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          closeModal();
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      {planning && (
        <>
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col>
                    <InputGroup size="sm">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Status
                      </InputGroup.Text>
                      <Form.Select
                        aria-label="Default select"
                        size="sm"
                        name="Status"
                        onChange={selectedOption => {
                          setStatusId(`${selectedOption.target.value}`);
                        }}
                        value={`${statusId}`}
                      >
                        <option key={'institucion_0'} value={0}>
                          Seleccione un Status
                        </option>
                        {!!statusList?.length &&
                          statusList
                            .filter(
                              status =>
                                status.idStatusDictamen != planning.id_status
                            )
                            .map(item => (
                              <option
                                key={item.idStatusDictamen}
                                value={item.idStatusDictamen}
                              >
                                {item.StatusDictamen}
                              </option>
                            ))}
                      </Form.Select>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormInputText
                      label="Observaciones"
                      changeHandler={onChange}
                      name={'Observaciones'}
                      control={control}
                      defaultValue={values.Observaciones}
                    ></FormInputText>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                  <Col>
                    <Button
                      type="submit"
                      color="primary"
                      size="sm"
                      className="right"
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
ConfirmPlaning.propTypes = {
  closeModal: PropTypes.func.isRequired,
  uidPlaning: PropTypes.string
};
export default ConfirmPlaning;
