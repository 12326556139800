import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import ItemsSeguimientos from './ItemsSeguimientos';
import { useAuth } from 'context/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [loadData, setLoadData] = useState(false);
  let history = useNavigate();
  const Auth = useAuth();
  useEffect(() => {
    var authCheck = Auth.getSessionData();
    if (!authCheck || authCheck?.length === 0 || authCheck === 'null') {
      history('/login');
      return;
      //Router.push('/login');
    } else {
      setLoadData(true);
    }
  }, []);
  return (
    <>
      <Card className="bg-100 shadow-none border mb-3">
        <Card.Body className="py-0">
          <Row className="g-0 justify-content-between">
            <Col sm="auto">
              <Flex alignItems="center">
                <img src={barChart} width={90} alt="..." className="ms-n4" />
                <div>
                  <h6 className="text-primary fs--1 mb-0">
                    Sistema de Seguimiento Estrategico
                  </h6>
                </div>
                <img
                  src={lineChart}
                  width={140}
                  alt="..."
                  className="ms-n4 d-md-none d-lg-block"
                />
              </Flex>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0"></Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>{loadData && <ItemsSeguimientos></ItemsSeguimientos>}</Row>
    </>
  );
};

export default Dashboard;
