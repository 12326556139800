import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { FormatCurrency, FormatDate } from 'helpers/FormatData';
import SoftBadge from 'components/common/SoftBadge';
import { Button, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
const ItemDetail = ({
  item,
  setPlanningForConfirmUid,
  setOpenModalPlanning
}) => {
  return (
    <tr>
      <td className={classNames('align-middle ps-0 text-nowrap')}>
        <Flex alignItems="center" className="position-relative">
          <div className="flex-1">
            <h6 className="mb-0">id:{item.vc_id}</h6>

            <h6 className="mb-0">{item.vc_name}</h6>
          </div>
        </Flex>
      </td>
      <td className={classNames('align-middle ps-0 text-nowrap')}>
        <Flex alignItems="center" className="position-relative">
          <div className="flex-1">
            <h6 className="mb-0">{FormatCurrency(item.n_amount)}</h6>
            <p className="mb-0">{item.vc_partidaspresupuestales}</p>
            <p className="mb-0">{item.uid_planing}</p>
          </div>
        </Flex>
      </td>
      <td className={classNames('align-middle ps-0 text-nowrap')}>
        <Flex alignItems="center" className="position-relative">
          <div className="flex-1">
            <h6 className="mb-0"> {item.vc_institucion}</h6>

            <p className="mb-0">Inicia: {FormatDate(item.dt_start)}</p>
            <p className="fs--2 mb-0">Expira: {item.dt_end}</p>
          </div>
        </Flex>
      </td>
      <td className={classNames('align-middle px-4')} style={{ width: '1%' }}>
        <SoftBadge
          bg={classNames({
            success: item.id_status == '2' || item.id_status == '5',
            warning: item.id_status == '1',
            danger: item.id_status == '3'
          })}
          className="fs--1 w-100"
        >
          {item.vc_statusdictamen}
        </SoftBadge>
        <Flex alignItems="center">
          <ProgressBar
            now={item.n_perccompleted}
            style={{ width: '80px', height: 5 }}
          />
          <div className="fw-semibold ms-3">
            {Number(item.n_perccompleted).toFixed(0)}%
          </div>
        </Flex>
      </td>
      {/* <td
          className={classNames('align-middle ps-4 pe-1')}
          style={{ width: '130px', minWidth: '130px' }}
        >
          <Form.Select size="sm" className="px-2 bg-transparent">
            <option value="action">Action</option>
            <option value="archive">Archive</option>
            <option value="delete">Delete</option>
          </Form.Select>
        </td>{' '} */}
      <td
        className={classNames('align-middle ps-4 pe-1')}
        style={{ width: '130px', minWidth: '130px' }}
        colSpan={3}
      >
        {item.id_status != '1' && (
          <Button
            variant="falcon-default"
            size="sm"
            onClick={() => {
              setPlanningForConfirmUid(item.uid_planing);
              setOpenModalPlanning(true);
            }}
          >
            <FontAwesomeIcon icon="check" />
          </Button>
        )}

        {item.id_status == '1' && (
          <>
            <Button
              variant="falcon-default"
              size="sm"
              title="Modificar Status de Ficha Tecnica"
              onClick={() => {
                setPlanningForConfirmUid(item.uid_planing);
                setOpenModalPlanning(true);
              }}
            >
              <FontAwesomeIcon icon="pencil" />
            </Button>
            <Link
              icon={<FontAwesomeIcon icon="rotate-right" />}
              to={`/Planning/${item.uid_planing}`}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  console.log(item.uid_planing);
                  //routeChange(`/DetalleReparacion/` + item.id_reparacion);
                }}
              >
                <FontAwesomeIcon icon="rotate-right" />
              </Button>
            </Link>
          </>
        )}
      </td>
    </tr>
  );
};
ItemDetail.propTypes = {
  item: PropTypes.object.isRequired,
  setPlanningForConfirmUid: PropTypes.func.isRequired,
  setOpenModalPlanning: PropTypes.func.isRequired
};
export default ItemDetail;
