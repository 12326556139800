export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Planeaciones',
      active: true,
      icon: 'home',
      to: '/'
    },
    {
      name: 'Fichas Tecnicas',
      active: true,
      icon: 'home',
      to: '/thechsh'
    }
  ]
};

export const configurationRoutes = {
  label: 'Configuracion',
  labelDisable: false,
  children: [
    {
      name: 'Instituciones',
      to: '/Instituciones',
      exact: true,
      icon: 'thumbtack',
      active: true
    },
    {
      name: 'PartidasPresupuestales',
      to: '/PartidasPresupuestales',
      exact: true,
      icon: 'thumbtack',
      active: true
    }
  ]
};
export const securityRoutes = {
  label: 'Seguridad',
  labelDisable: false,
  children: [
    {
      name: 'Usuarios',
      to: '/Users',
      exact: true,
      icon: 'thumbtack',
      active: true
    }
  ]
};
export default [dashboardRoutes, configurationRoutes, securityRoutes];
