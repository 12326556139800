import React, { useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useEffect } from 'react';

import { Card, Col, Row, Table } from 'react-bootstrap';

import MyModal from 'helpers/MyModal';
import CreatePlanning from 'pages/plannings/CreatePlanning';
import { httpClient } from 'helpers/HttpClient';
import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import ItemDetail from './_ItemDetail';
import ConfirmTechSheet from 'pages/plannings/_ConfirmTechSheet';

const ItemsTechSheets = () => {
  const [items, setItems] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [openModalPlanning, setOpenModalPlanning] = useState(null);
  const [planningForConfirmUid, setPlanningForConfirmUid] = useState(null);

  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleClosePlanning = () => {
    setOpenModalPlanning(false);
    getPlannings();
  };
  const getPlannings = async () => {
    setItems(null);

    httpClient(
      'POST',
      ApiEndpoint + 'sheets/get',
      {
        userid: userData.uid_user,
        status: 0
      },
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          setItems(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getPlannings();
  }, []);

  return (
    <>
      <FalconCardHeader
        title="Fichas Tecnicas"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card className="overflow-hidden">
        <Card.Body className="py-0">
          <Table className="table-dashboard mb-0 fs--1">
            <tbody>
              {items &&
                items.map(item => (
                  <ItemDetail
                    setPlanningForConfirmUid={setPlanningForConfirmUid}
                    setOpenModalPlanning={setOpenModalPlanning}
                    item={item}
                    key={item.id}
                  />
                ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          <Row className="flex-between-center">
            <Col xs="auto"></Col>
            <Col xs="auto"></Col>
          </Row>
        </Card.Footer>
      </Card>
      {openModal && (
        <MyModal
          id="id_myModal"
          title={'Agregar Nueva Planeación'}
          openModal={openModal}
          closeModal={handleClose}
        >
          <CreatePlanning closeModal={handleClose} />
        </MyModal>
      )}{' '}
      {openModalPlanning && (
        <MyModal
          id="id_myModal2"
          title={'Actualizar Ficha Tecnica'}
          openModal={openModalPlanning}
          closeModal={handleClosePlanning}
        >
          <ConfirmTechSheet
            uidPlaning={planningForConfirmUid}
            closeModal={handleClosePlanning}
          />
        </MyModal>
      )}
    </>
  );
};

export default ItemsTechSheets;
