import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
const URI = ApiEndpoint + 'configuration/';
import PropTypes from 'prop-types';
import { FormInputText } from 'components/formcomponents/FormInputText';
import { httpClient } from 'helpers/HttpClient';
import { useAuth } from 'context/AuthProvider';

const EditInstitucion = ({ idInstitucion, closeModal }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [validated, setValidated] = useState(false);
  const [institucion, setInstitucion] = useState(null);
  const { control, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      Institucion: ''
    }
  });
  useEffect(() => {
    httpClient(
      'GET',
      URI + 'instituciones/' + idInstitucion,
      {},
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          setInstitucion(response.data.Institucion);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    if (idInstitucion > 0) {
      httpClient(
        'PUT',
        URI + 'instituciones/' + idInstitucion,
        { Institucion: institucion },
        userData.token
      )
        .then(function (response) {
          if (!response.data.error) {
            closeModal();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      httpClient(
        'POST',
        URI + 'instituciones/',
        { Institucion: institucion },
        userData.token
      )
        .then(function (response) {
          if (!response.data.error) {
            closeModal();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label="Institucion"
                  changeHandler={event => setInstitucion(event.target.value)}
                  name={'institucion'}
                  control={control}
                  defaultValue={institucion}
                ></FormInputText>
              </Col>
            </Row>

            <Button type="submit" color="primary" size="sm">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

EditInstitucion.propTypes = {
  idInstitucion: PropTypes.number.idInstitucion,
  closeModal: PropTypes.func.isRequired
};

export default EditInstitucion;
