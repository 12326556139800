import React, { useState } from 'react';
import { Button, Col, Form, Row, Toast } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import { useAuth } from 'context/AuthProvider';

const LoginForm = ({ hasLabel }) => {
  const Auth = useAuth();
  let history = useNavigate();
  const [show, setShow] = useState(false);
  // State
  const [formData, setFormData] = useState({
    user: '',
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'users/login';
    axios
      .post(URI, {
        user: formData.user,
        password: formData.password
      })
      .then(function (response) {
        if (!response.data.error) {
          Auth.login(response.data.user[0], response.data.token);
          return {
            isAuth: true,
            response: response.data
          };
        }
        return { isAuth: false };
      })

      .then(isAuth => {
        if (isAuth == false) {
          setShow(true);
        } else {
          history('/');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Usuario</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Usuario' : ''}
          value={formData.user}
          name="user"
          onChange={handleFieldChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0">Remember Me</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
      <Form.Control.Feedback>
        Provide a valid User and Password
      </Form.Control.Feedback>
      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.user || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
      <Toast show={show} onClose={() => setShow(!show)} delay={3000} autohide>
        <Toast.Header>
          <strong className="me-auto">Invalid Data</strong>
        </Toast.Header>
        <Toast.Body>Please Provide a valid user data</Toast.Body>
      </Toast>
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  hasLabel: false
};

export default LoginForm;
