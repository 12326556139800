import { getColor } from './utils';

export const chartJsDefaultTooltip = () => ({
  backgroundColor: getColor('100'),
  borderColor: getColor('300'),
  borderWidth: 1,
  titleColor: getColor('black'),
  callbacks: {
    labelTextColor() {
      return getColor('black');
    }
  }
});

export const dynamicColors = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};
