import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Form, ProgressBar, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import SimpleBar from 'simplebar-react';
import Flex from 'components/common/Flex';
import IconItem from 'components/common/icon/IconItem';
import CardDropdown from 'components/common/CardDropdown';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import { useAuth } from 'context/AuthProvider';
import { httpClient } from 'helpers/HttpClient';
import { useParams } from 'react-router-dom';
import { dynamicColors } from 'helpers/chartjs-utils';
import axios from 'axios';
const ToDoListItem = ({
  task,
  index,
  statusTasks,
  onClickStatus,
  handleDownload
}) => {
  return (
    <Flex
      justifyContent="between"
      className="hover-actions-trigger  btn-reveal-trigger px-x1 hover-bg-100"
      alignItems="center"
    >
      <Form.Check
        className="py-3 mb-0 d-flex align-items-center"
        id={`todoItem-${index}`}
      >
        <Form.Check.Input
          type="checkbox"
          defaultChecked={task.completed}
          onChange={e => {
            e.preventDefault();
            onClickStatus(task.id, 0);
          }}
          className={classNames('rounded-3 mt-0', {
            'form-check-line-through': task.completed
          })}
          disabled={task.completed}
        />
        <Form.Check.Label className="flex-1 mb-0 ms-3">
          <Row>
            <Col>
              <h6 className="mb-1">{task.title}</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="fs-10">
                <span className="fw-medium text-600 ms-2">{task.text}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="fs-10">
                <span className="fw-medium text-600 ms-2">
                  Status: {task.status}{' '}
                  {task.comments ? ' / ' + task.comments : ''}
                </span>
              </div>
            </Col>
          </Row>
        </Form.Check.Label>
      </Form.Check>
      <div className="d-flex align-items-right">
        {task.file != '' && (
          <>
            <IconItem
              tag="button"
              icon="file-text"
              size="sm"
              title="Adjunto"
              className="btn rounded-3 me-2 fs-11"
              onClick={e => {
                e.preventDefault();
                handleDownload(task.file);
              }}
            />{' '}
          </>
        )}
        {/* {!task.completed && (
          <>
            {' '}
            <IconItem
              tag="button"
              icon="clock"
              size="sm"
              title="Fecha compromiso"
              className="btn rounded-3 me-2 fs-11"
              onClick={e => {
                console.log(task.id);
                e.preventDefault();
              }}
            />{' '}
          </>
        )} */}

        <CardDropdown
          btnRevealClass="btn-reveal-sm"
          drop={index > 1 ? 'start' : undefined}
        >
          {statusTasks &&
            statusTasks.map(item => {
              return (
                <Dropdown.Item
                  key={item.idStatusTask}
                  value={item.idStatusTask}
                  onClick={e => {
                    e.preventDefault();
                    onClickStatus(task.id, item.idStatusTask);
                  }}
                >
                  {item.StatusTask}
                </Dropdown.Item>
              );
            })}
        </CardDropdown>
      </div>
    </Flex>
  );
};

ToDoListItem.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    id: PropTypes.number.isRequired,
    completed: PropTypes.bool,
    comments: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,

    file: PropTypes.string
  }),
  index: PropTypes.number.isRequired,
  statusTasks: PropTypes.array,
  handleChange: PropTypes.func,
  handleDownload: PropTypes.func,
  onClickStatus: PropTypes.func
};
const CheckPlanning = ({
  setTaskIdSelected,
  openEditTask,
  uidRefresh,
  setStatusIdSelected
}) => {
  let { id } = useParams();
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [tasks, setTasks] = useState(null);
  const [perc, setPerc] = useState(null);
  const [percComplete, setPercComplete] = useState(null);
  const [statusTasks, setStatusTasks] = useState(null);
  const getTasks = () => {
    httpClient(
      'POST',
      ApiEndpoint + 'configuration/tasks',
      {
        userid: userData.uid_user,
        id: id
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          setTasks(response.data);

          let sumTotal = response.data.filter(function (task) {
            return task.b_completed;
          }).length;
          var result = [];
          response.data.reduce(function (task, value) {
            if (!task[value.vc_statustask]) {
              task[value.vc_statustask] = {
                Id: value.vc_statustask || 'Pendientes',
                qty: 0,
                color: dynamicColors()
              };
              result.push(task[value.vc_statustask]);
            }
            task[value.vc_statustask].qty += 1;
            return task;
          }, {});
          console.log('result', result);
          setPercComplete(result);
          setPerc(Math.round((sumTotal / response.data.length) * 100));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDownload = filename => {
    const URIFiles = ApiEndpoint + 'task/getfile';
    const config = { responseType: 'blob', path: filename };
    // axios.post(URIFiles, config).then(response => {
    //     new File(response, filename);
    // });

    axios({
      url: URIFiles,
      method: 'POST',
      data: config,
      responseType: 'blob', // important
      headers: { Authorization: `Bearer ${userData.token}` }
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });

    // httpClient('POST', URIFiles, config, userData.token, {
    //   responseType: 'blob'
    // }).then(response => {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   console.log('url', url);
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', filename);
    //   document.body.appendChild(link);
    //   link.click();
    // });
  };

  const getStatusTask = () => {
    httpClient(
      'GET',
      ApiEndpoint + 'configuration/statustasks',
      {},
      userData.token
    )
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          setStatusTasks(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getStatusTask();
    getTasks();
  }, []);

  useEffect(() => {
    setTasks(null);
    getTasks();
  }, [uidRefresh]);
  const handleChange = (id, checked) => {
    console.log(id, checked);
  };
  const clickStatus = (id, idStatusTask) => {
    console.log(id, idStatusTask);
    setTaskIdSelected(id);
    setStatusIdSelected(idStatusTask);
    openEditTask(true);
  };
  const avColors = ['primary', 'info', 'success', '200'];
  return (
    <>
      <Card className="h-100 recent-activity-card">
        <FalconCardHeader
          title="Documentos requeridos"
          titleTag="h6"
          endEl={<span className="fw-bold">{perc}%</span>}
        />
        <SimpleBar style={{ height: '26rem' }}>
          <Card.Body className="ps-2 recent-activity-body-height">
            <Row>
              <Col>
                <ProgressBar
                  style={{ height: 10 }}
                  className="shadow-none rounded-4 mb-3"
                >
                  {percComplete &&
                    tasks &&
                    percComplete.map((task, index) => (
                      <ProgressBar
                        // variant={status.color}
                        variant={
                          task.Id === 'Pendientes' ? 'warning' : avColors[index]
                        }
                        now={(task.qty * 100) / tasks.length}
                        key={task.Id || ''}
                        className={classNames(
                          'overflow-visible position-relative',
                          {
                            'rounded-end rounded-pill': index === 0,
                            'rounded-start rounded-pill':
                              index === percComplete.length - 1,
                            'border-end border-100 border-2':
                              index !== percComplete.length - 1,
                            'rounded-0':
                              index !== percComplete.length - 1 && index !== 0
                          }
                        )}
                      />
                    ))}
                </ProgressBar>
              </Col>
            </Row>
            <Row className="fs-10 fw-semibold text-500">
              {percComplete &&
                tasks &&
                percComplete.map((status, index) => (
                  <Col
                    xs={6}
                    sm="auto"
                    as={Flex}
                    alignItems="center"
                    className="pe-2"
                    key={status.Id}
                  >
                    <span
                      className={`dot bg-${
                        status.Id === 'Pendientes' ? 'warning' : avColors[index]
                      }`}
                    ></span>

                    <span>{status.Id || 'Pendientes'}</span>
                    <span className="d-none d-md-inline-block d-lg-none d-xxl-inline-block ms-1">
                      ({status.qty})
                    </span>
                  </Col>
                ))}
            </Row>
            <hr />
            {/* <Row className="gx-3 align-items-center my-3 px-x1">
              <Col xs="auto">
                <h6 className="text-primary mb-0">{perc}%</h6>
              </Col>
              <Col>
                <ProgressBar style={{ height: '0.5625rem' }}>
                  <ProgressBar
                    now={perc}
                    key={1}
                    variant="progress-gradient"
                    className="rounded-pill"
                  />
                </ProgressBar>
              </Col>
            </Row> */}
            {tasks &&
              tasks.map(task => {
                return (
                  <ToDoListItem
                    key={task.id_task}
                    index={task.id_task}
                    task={{
                      id: task.id_task,
                      title: task.vc_name,
                      text: task.vc_description,
                      icon: 'envelope',
                      comments: task.vc_comments,
                      status: task.vc_statustask || 'Pendiente',
                      completed: task.b_completed || false,
                      file: task.vc_file || ''
                    }}
                    handleChange={handleChange}
                    statusTasks={statusTasks}
                    onClickStatus={clickStatus}
                    handleDownload={handleDownload}
                  ></ToDoListItem>
                );
              })}
          </Card.Body>
        </SimpleBar>
      </Card>
    </>
  );
};
CheckPlanning.propTypes = {
  setTaskIdSelected: PropTypes.func,
  setStatusIdSelected: PropTypes.func,
  openEditTask: PropTypes.func,
  uidRefresh: PropTypes.string
};

export default CheckPlanning;
