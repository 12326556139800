import { Button, Modal, Row } from 'react-bootstrap';
import React from 'react';
import propTypes from 'prop-types';

const ConfirmAction = ({
  open,
  handleClose,
  title,
  message,
  ConfirmAction
}) => {
  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          handleClose(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>{message}</Row>
          <Row></Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              ConfirmAction(false);
              handleClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              ConfirmAction(true);
              handleClose(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ConfirmAction.propTypes = {
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  message: propTypes.string.isRequired,
  ConfirmAction: propTypes.func.isRequired
};
export default ConfirmAction;
