import React, { createContext, useContext, useMemo, useState } from 'react';
import { useLocalStorage } from './LocalSess';
import { useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from 'helpers/Encrypth';
import PropTypes from 'prop-types';
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useLocalStorage('loginssesystem', null);
  const [auth, setAuth] = useState(null);
  const navigate = useNavigate();

  const login = async (data, token) => {
    setAuth(true);

    data.token = token;

    setUserData(encryptData(data));
  };

  // call this function to sign out logged in user
  const logout = () => {
    setAuth(false);
    setUserData(null);

    navigate('/', { replace: true });
  };

  const getSessionData = () => {
    if (!userData) return null;
    const dataUserEncr = decryptData(userData);
    return dataUserEncr;
  };
  const value = useMemo(
    () => ({
      userData,
      auth,
      login,
      logout,
      getSessionData
    }),
    [userData]
  );
  AuthProvider.propTypes = {
    children: PropTypes.node
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
