import { FormInputText } from 'components/formcomponents/FormInputText';
import { useAuth } from 'context/AuthProvider';
import { ApiEndpoint } from 'helpers/ApiEndPoint';
import { httpClient } from 'helpers/HttpClient';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';

const CreatePlanning = ({ closeModal }) => {
  const Auth = useAuth();
  var userData = Auth.getSessionData();
  const [institucionesList, setInstitucionesList] = useState([]);
  const [institucionId, setInstitucionId] = useState(null);
  const [partidasList, setPartidasList] = useState([]);
  const [partidaPresupuestal, setPartidaPresupuestal] = useState(null);
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    Clave: '',
    Name: '',
    Start: '',
    End: '',
    Amount: '0',
    Area: '',
    TipoContratacion: ''
  });
  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      Clave: '',
      Name: '',
      Start: '',
      End: '',
      Amount: '0',
      Area: '',
      TipoContratacion: ''
    }
  });

  const getInstitucionesList = async () => {
    setInstitucionesList(null);

    httpClient(
      'GET',
      ApiEndpoint + 'configuration/instituciones',
      {},
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          setInstitucionesList(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPartidasPresupuestalesList = async () => {
    setPartidasList(null);

    httpClient(
      'GET',
      ApiEndpoint + 'configuration/partidaspresupuestales',
      {},
      userData.token
    )
      .then(function (response) {
        if (!response.data.error) {
          setPartidasList(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const URI = ApiEndpoint + 'plannings/create';
    setValidated(true);

    httpClient(
      'POST',
      URI,
      {
        IdPlanning: uuid(),
        VcId: values.Clave,
        Name: values.Name,
        IdInstitucion: institucionId,
        Start: values.Start,
        End: values.End,
        IdPartidaPresupuestal: partidaPresupuestal,
        Amount: values.Amount,
        Area: values.Area,
        IdTipoContratacion: values.TipoContratacion,
        IdStatus: 1,
        IdUser: userData.uid_user
      },
      userData.token
    )
      .then(function (response) {
        console.log(response);
        if (!response.data.error) {
          closeModal();
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getInstitucionesList();
    getPartidasPresupuestalesList();
  }, []);
  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label="ID"
                  changeHandler={onChange}
                  name={'Clave'}
                  control={control}
                  defaultValue={values.Clave}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Nombre"
                  changeHandler={onChange}
                  name={'Name'}
                  control={control}
                  defaultValue={values.Name}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup size="sm">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    Institucion
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Default select"
                    size="sm"
                    name="institucion"
                    onChange={selectedOption => {
                      setInstitucionId(`${selectedOption.target.value}`);
                    }}
                    value={`${institucionId}`}
                  >
                    <option key={'institucion_0'} value={0}>
                      Seleccione una Institucion
                    </option>
                    {!!institucionesList?.length &&
                      institucionesList.map(item => (
                        <option
                          key={item.idInstituciones}
                          value={item.idInstituciones}
                        >
                          {item.Institucion}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Inicio"
                  changeHandler={onChange}
                  name={'Start'}
                  control={control}
                  defaultValue={values.Start}
                  type="date"
                ></FormInputText>
              </Col>
              <Col>
                <FormInputText
                  label="Expira"
                  changeHandler={onChange}
                  name={'End'}
                  control={control}
                  defaultValue={values.End}
                  type="date"
                ></FormInputText>
              </Col>
            </Row>{' '}
            <Row>
              <Col>
                <FormInputText
                  label="Amount"
                  changeHandler={onChange}
                  name={'Amount'}
                  control={control}
                  defaultValue={values.Amount}
                  type="number"
                ></FormInputText>
              </Col>
              <Col>
                <FormInputText
                  label="Area"
                  changeHandler={onChange}
                  name={'Area'}
                  control={control}
                  defaultValue={values.Area}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup size="sm">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    Partida Presupuestal
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Default select"
                    size="sm"
                    name="partida"
                    onChange={selectedOption => {
                      setPartidaPresupuestal(`${selectedOption.target.value}`);
                    }}
                    value={`${partidaPresupuestal}`}
                  >
                    <option key={'partida_0'} value={0}>
                      Seleccione una Partida Presupuestal
                    </option>
                    {!!partidasList?.length &&
                      partidasList.map(item => (
                        <option
                          key={item.IdPartidasPresupuestales}
                          value={item.IdPartidasPresupuestales}
                        >
                          {item.PartidasPresupuestales}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputText
                  label="Tipo de Contratacion"
                  changeHandler={onChange}
                  name={'TipoContratacion'}
                  control={control}
                  defaultValue={values.TipoContratacion}
                ></FormInputText>
              </Col>
            </Row>
            <Row>
              <Button type="submit" color="primary" size="sm">
                Guardar
              </Button>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
CreatePlanning.propTypes = {
  closeModal: PropTypes.func.isRequired
};
export default CreatePlanning;
