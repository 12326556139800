import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

export const FormInputText = ({
  control,
  name,
  label,
  defaultValue,
  changeHandler,
  required = true,
  type = 'text'
}) => {
  return (
    <Form.Group className="mb-3" controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <Form.Control
            value={defaultValue || ''}
            type={type || 'text'}
            label={`${label}`}
            name={name}
            required={required}
            placeholder={`${label}`}
            onChange={event => {
              onChange(event);
              changeHandler(event);
            }}
          />
        )}
      />
      <Form.Control.Feedback type="invalid">
        Dato Requerido
      </Form.Control.Feedback>
    </Form.Group>
  );
};

FormInputText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  changeHandler: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string
};
