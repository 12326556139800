import IconButton from 'components/common/IconButton';
import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import propTypes from 'prop-types';

const GenericTableHeader = ({ label, newFunction, isFinished = false }) => {
  return (
    <Card.Header>
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          <div id="orders-actions">
            {!isFinished && (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => {
                  newFunction();
                }}
              >
                <span className="d-none d-sm-inline-block ms-1">Agregar</span>
              </IconButton>
            )}
          </div>
        </Col>
      </Row>
    </Card.Header>
  );
};

GenericTableHeader.propTypes = {
  label: propTypes.string.isRequired,
  newFunction: propTypes.func.isRequired,
  isFinished: propTypes.bool
};

export default GenericTableHeader;
